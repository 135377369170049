import { Container, Row, Col, Image } from 'react-bootstrap';
import { fetchAboutData } from '../../sanityQueries';
import { useState, useEffect } from 'react';
import { PortableText } from '@portabletext/react';

import './Intro-View.scss';

export const IntroView = () => {
    const [aboutData, setAboutData] = useState([]);
    useEffect(() => {
        fetchAboutData().then((data) => {
            setAboutData(data[0]);
        });
    }, []);

    const introImage = aboutData?.introImageUrl;

    return (
        <Container>
            <Row>
                <Col md={6}>
                    <Row>
                        <h1 className="section-title">I'm Jeriko Carrera,</h1>
                    </Row>
                    <Row>
                        <Col>
                            <Row className="intro-bio">
                                <PortableText value={aboutData.introBio} />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Container className="image-wrapper">
                            {introImage && (
                                <Image
                                    id="intro-image"
                                    src={introImage}
                                    alt="Profile"
                                />
                            )}
                        </Container>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};