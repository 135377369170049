import { Container, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { fetchSocialLinksData, fetchAboutData } from '../../sanityQueries';

import './Footer-View.scss';

export const FooterView = () => {
    const [socialLinksData, setSocialLinksData] = useState([]);
    const [resumeData, setResumeData] = useState(null);

    useEffect(() => {
        fetchSocialLinksData().then((data) => {
            setSocialLinksData(data);
        });
    }, []);

    useEffect(() => {
        fetchAboutData().then((data) => {
            setResumeData(data[0]);
        });
    }, []);

    const resumeUrl = resumeData?.resume;
    return (
        <Container className="footer-section">
            <Row className="footer-content">
                <blockquote className="blockquote">
                    <p>
                        “I found I could say things with color and shapes that I couldn’t say any other way—things I had no words for.”
                    </p>
                    <footer className="blockquote-footer">
                        <cite title="Source Title">Georgia O’Keeffe</cite>
                    </footer>
                </blockquote>
                <Container>
                    <ul className="links">
                        <li>
                            <a href={resumeUrl} id="button" download target="_blank" rel="noreferrer">
                                <p>CV</p>
                            </a>
                        </li>
                        {socialLinksData.map((link, index) => {
                            switch (link.contactType) {
                                case 'link':
                                    return (
                                        <li key={index}>
                                            <a href={link.socialLink} target="_blank" rel="noreferrer">
                                                <p>{link.socialName}</p>
                                            </a>
                                        </li>
                                    );
                                case 'phone':
                                    return (
                                        <li key={index}>
                                            <a href={`tel:${link.phoneNumber}`} target="_blank" rel="noreferrer">
                                                <p>Phone</p>
                                            </a>
                                        </li>
                                    );
                                case 'email':
                                    return (
                                        <li key={index}>
                                            <a href={`mailto:${link.email}`} target="_blank" rel="noreferrer">
                                                <p>Email</p>
                                            </a>
                                        </li>
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </ul>
                </Container>
            </Row>
        </Container>

    )
}