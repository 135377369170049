import { createRoot } from 'react-dom/client';
import { useRef } from 'react';
import { MainView } from './components/Main-View/Main-View';
import { NavBarComponent } from './components/Nav-Bar/Nav-Bar';
import { WelcomePageComponent } from './components/Welcome-Page/Welcome-Page';
import { ScrollButtonComponent } from './components/Scroll-Button/Scroll-Button'
import { Container, Row } from 'react-bootstrap';
import './index.scss';

const WebDevPortfolio = () => {
    const mainView = useRef();

    const scrollTo = (section) => {
        section.current.scrollIntoView({ behavior: "smooth", block: 'start', inline: "nearest" })
    }

    return (
        <Container className="full-body">
            <Row id="welcome-view">
                <WelcomePageComponent scrollTo={scrollTo} nextSection={mainView} />
            </Row>
            <NavBarComponent />
            <div ref={mainView} >
                <MainView />
            </div>
            
            <ScrollButtonComponent />
        </Container>
    );
};

const container = document.querySelector('#root');
const root = createRoot(container);

root.render(<WebDevPortfolio />);

