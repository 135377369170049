import client, { urlFor } from './sanityClient';

// Fetch Projects Data
export const fetchProjectsData = async () => {
    const data = await client.fetch(`*[_type == "projects"]`);
    const projectsData = data.map(item => ({
        ...item,
        imageUrl: item.imageUrl ? urlFor(item.imageUrl).url() : null, // Generate URL for image
    }));
    return projectsData;
};

// Fetch About Data
export const fetchAboutData = async () => {
    const data = await client.fetch(`*[_type == "aboutMe"]{
        introBio,
        introImage,
        aboutBio,
        aboutImage,
        resume {
            asset->{
                _id,
                url
            }
        }
    }`);

    const aboutData = data.map(item => ({
        ...item,
        introImageUrl: item.introImage ? urlFor(item.introImage).url() : null, // Generate URL for intro image
        aboutImageUrl: item.aboutImage ? urlFor(item.aboutImage).url() : null, // Generate URL for about image
        resume: item.resume?.asset?.url || null // Generate URL for resume
    }));

    return aboutData;
};

// Fetch Social Links Data
export const fetchSocialLinksData = async () => {
    const query = `*[_type == "socialLinks"]{
      "contactType": contactInfo.contactType,
      "socialName": contactInfo.socialName,
      "socialLink": contactInfo.socialLink,
      "phoneNumber": contactInfo.phoneNumber,
      "email": contactInfo.email
    }`;
    const data = await client.fetch(query);
    return data;
};


