import { Container, Row, Form, Button, InputGroup } from 'react-bootstrap';

import './Contact-View.scss';

export const ContactView = () => {
    return (
        <Container>
            <Row>
                <h1 className="section-title pt-3">Contact me:</h1>
            </Row>
            <Row>
                <Form action="https://public.herotofu.com/v1/83204e00-e579-11ee-97a2-f17dd40c9cd8" method="post" acceptCharset="UTF-8">
                    <Form.Group className="mb-3">
                        <Form.Control name="Name" id="name" type="text" placeholder="Enter name" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control name="Email" id="email" type="email" placeholder="Enter email" required />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Control name="Phone" id="phone-number" type="text" placeholder="Enter phone number" required />
                    </Form.Group>
                    <InputGroup>
                        <InputGroup.Text>Write your message: </InputGroup.Text>
                        <Form.Control name="Message" className="message-area" as="textarea" aria-label="With textarea" />
                    </InputGroup>
                    <Button className="mt-3 p-2 btn-sm btn-dark submit-button" type="submit">
                        <p className="m-0">Submit</p>
                    </Button>
                </Form>

            </Row>
        </Container>
    )
};