import { Container, Row, Col, Image } from 'react-bootstrap';
import { fetchAboutData } from '../../sanityQueries';
import { useState, useEffect } from 'react';
import { PortableText } from '@portabletext/react';

import './About-View.scss';

export const AboutView = () => {
    const [aboutData, setAboutData] = useState([]);
    useEffect(() => {
        fetchAboutData().then((data) => {
            setAboutData(data[0]);
        });
    }, []);

    const aboutImage = aboutData?.aboutImageUrl;

    return (
        <Container id="about-container">
            <Row>
                <Col md={5}>
                    <Row>
                        <Container className="image-wrapper">
                            <Image id="about-image" src={aboutImage} alt="Profile"></Image>
                        </Container>
                    </Row>
                </Col>
                <Col md={7}>
                    <Row id="about-section">
                        <Row>
                            <h1 className="section-title">About me</h1>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="about-bio">
                                   <PortableText value={aboutData.aboutBio} />
                                </Row>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
};